<template>
  <div class="row" style="padding-bottom:60px">
    <div class="col-md-2 hidden-sm hidden-xs"></div>
        <div class="col-md-8 col-sm-12">
          <div class="welcome">
        <h4>{{ $t('aml-policy.title') }}</h4>
        <button class="terms-accordion" @click="toggleAccordian">{{ $t('aml-policy.title1') }}</button>
        <div class="terms-conditions">
            <div class="terms-body">
                <p>{{ $t('aml-policy.title1-p') }}</p>
            </div>
        </div>
        <button class="terms-accordion" @click="toggleAccordian">{{ $t('aml-policy.title2') }}</button>
        <div class="terms-conditions">
            <div class="terms-body">
                <p>
                    <span>{{ $t('aml-policy.title2-p') }}</span>
                    <span>{{ $t('aml-policy.title2-p1') }}</span>
                    <span>{{ $t('aml-policy.title2-p2') }}</span>
                </p>
            </div>
        </div>
        <button class="terms-accordion" @click="toggleAccordian">{{ $t('aml-policy.title3') }}</button>
        <div class="terms-conditions">
            <div class="terms-body">
                <ul style="list-style: lower-alpha;">
                    <li>{{ $t('aml-policy.title3-p1') }}</li>
                    <li>{{ $t('aml-policy.title3-p2') }}</li>
                    <li>{{ $t('aml-policy.title3-p3') }}</li>
                    <li>{{ $t('aml-policy.title3-p4') }}</li>
                    <li>{{ $t('aml-policy.title3-p5') }}</li>
                    <li>{{ $t('aml-policy.title3-p6') }}</li>
                    <li>{{ $t('aml-policy.title3-p7') }}</li>
                    <li>{{ $t('aml-policy.title3-p8') }}</li>
                    <li>{{ $t('aml-policy.title3-p9') }}</li>
                    <li>{{ $t('aml-policy.title3-p10') }}</li>
                    <li>{{ $t('aml-policy.title3-p11') }}</li>
                    <li>{{ $t('aml-policy.title3-p12') }}</li>
                    <li>{{ $t('aml-policy.title3-p13') }}</li>
                </ul>
            </div>
        </div>
    </div>
    </div>
      
      </div>
  </template>
  
  <script>
  export default {
    name: 'terms-and-conditions',
    methods : {
      toggleAccordian: function(event) {
        var element = event.target;
        element.classList.toggle("active");
            
        var panel = element.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      }
    }
  }
  </script>
  
  <style scoped>
  
  </style>